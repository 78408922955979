<template>
  <div style="position: relative; width: 32px">
    <SpeedDialBtn
      buttonClass="p-button-secondary p-button-outlined"
      type="small"
      :style="
        'position: absolute; left: ' +
        (length > 1 ? -40 * length : 0) +
        'px; top: -15px'
      "
      :actions="actions"
      :service="service"
      @load="$emit('load')"
      v-model:length="length"
    />
  </div>
</template>

<script>
import SpeedDialBtn from "./SpeedDialBtn.vue";

export default {
  components: { SpeedDialBtn },
  props: {
    actions: Array | Function,
    service: Object,
  },
  emits: ["load"],
  data() {
    return {
      length: 0,
    };
  },
};
</script>
