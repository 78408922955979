<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <bread-crumb
            :breadcrumbs="[
              { label: 'Отпуска', routeName: 'Vacation' },
              { label: 'Просмотр' },
            ]"
          ></bread-crumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="page-title">Отпуск</h3>
            <DetailListView
              editRoute="VacationEdit"
              :service="service"
              :columns="[
                {
                  field: 'userName',
                  label: 'Сотрудник',
                  icon: 'pi pi-user',
                  slot: 'userName',
                },
                {
                  field: 'start',
                  label: 'Срок',
                  content: (data) => {
                    return (
                      Str.date(service.dtStart) +
                      ' - ' +
                      Str.date(service.dtEnd)
                    );
                  },
                  icon: 'pi pi-calendar',
                },
              ]"
            >
              <template #userName="{ data }">
                <div v-if="data">
                  <router-link
                    class="text-info link_underline"
                    :to="{ name: 'UserShow', params: { id: data.id } }"
                  >
                    {{ data.surname }} {{ data.name }} {{ data.secondName }}
                  </router-link>
                </div>
              </template>
            </DetailListView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import Str from "/src/utils/Str";
import VacationService from "../services/VacationService";
import DetailListView from "@/components/ui/DetailListView.vue";

export default {
  components: {
    BreadCrumb,
    DetailListView,
  },
  data() {
    return {
      service: new VacationService({}),
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
  },
};
</script>
