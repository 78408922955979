<template>
  <div class="row">
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item d-md-flex"
        v-for="(col, i) in fillColumns"
        :key="i"
      >
        <div class="d-flex align-item-center w-100 lg-md-50">
          <div class="me-2">
            <i :class="col.icon"></i>
          </div>
          <div class="text-black-50">{{ col.label }}</div>
        </div>
        <div class="w-100 lg-md-50">
          <DetailViewCell
            v-for="(colItem, colIndex) in col.items || [col]"
            :key="colIndex"
            :col="colItem"
            :data="service"
            :class="colItem.class"
            @click="colItem.onClick ? colItem.onClick(service) : () => {}"
          >
            <slot
              v-if="colItem.slot"
              :name="colItem.slot"
              :data="value(colItem)"
            ></slot>
          </DetailViewCell>
        </div>
      </li>
    </ul>
    <SpeedDialBtn :items="dialBtnItems" icon="pi pi-align-center" />
  </div>
</template>

<script>
import Button from "primevue/button";
import Link from "./Link.vue";
import FormField from "./form/FormField.vue";
import DetailViewCell from "./DetailViewCell.vue";
import Str from "/src/utils/Str";
import SpeedDialBtn from "@/components/ui/SpeedDialBtn";

export default {
  components: {
    Button,
    Link,
    FormField,
    DetailViewCell,
    SpeedDialBtn,
  },
  props: {
    service: Object,
    columns: Array,
    countColumns: Number,
    editRoute: String,
    actions: Array,
  },
  data() {
    return {
      Str: Str,
      editCell: false,
      dialBtnItems: [
        {
          label: "Редактировать",
          icon: "pi pi-pencil text-warning",
          command: () => {
            this.$router.push({
              name: this.editRoute,
              params: { id: this.service.id },
            });
          },
        },
      ],
    };
  },
  created() {
    if (this.actions) {
      this.actions.forEach((element) => {
        this.dialBtnItems.push(element);
      });
    }
  },
  methods: {
    async onSubmit() {
      await this.service.save();
      // @todo: Закрывать окно редактирования
      // сейчас если комментарий пустой, при вводе одного значения - окно сразу закрывается
      // и только затем можно продолжить
      this.editCell = this.editCell ? false : true;
    },
    // @todo: Дублирование кода с DetailViewCell. Оптимизировать
    value(col) {
      // Берем значение, если оно составное, т.е. передано как массив из вложенных ключек к переменной элемента сервиса
      if (typeof col.field === "object") {
        let value = this.service;

        for (let f of col.field) {
          if (value[f] === undefined) {
            return null;
          }
          value = value[f];
        }

        return value;
      }

      return this.service[col.field];
    },
  },
  computed: {
    fillColumns() {
      let fillColumns = [];

      for (let i in this.columns) {
        fillColumns.push(this.columns[i]);
      }

      return fillColumns;
    },
    columnOne: function () {
      return this.fillColumns.filter((i) => i.column === 1);
    },
    columnTwo: function () {
      return this.fillColumns.filter((i) => i.column === 2);
    },
  },
};
</script>
