import Service from '../../../services/Service';

class VacationService extends Service {

    basePath = 'vacation';

    newObject() {
        return new VacationService();
    }
    
}

export default VacationService;