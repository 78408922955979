<template>
  <div class="speeddial-tooltip-demo">
    <SpeedDial
      :model="items"
      direction="left"
      :showIcon="icon"
      buttonClass="p-button-success text-light"
      style="position: fixed; top: 90%; right: 1rem"
      :tooltipOptions="{ position: 'left' }"
      @click="goCreate()"
    >
    </SpeedDial>
  </div>
</template>

<script>
import Button from "primevue/button";
import SpeedDial from "primevue/speeddial";

export default {
  components: {
    Button,
    SpeedDial,
  },
  props: {
    items: Array,
    icon: String,
    actions: Array,
  },
  data() {
    return {};
  },
  methods: {
    goCreate() {
      if (
        this.actions &&
        this.actions.length == 1 &&
        this.actions[0].name == "create"
      ) {
        this.$router.push({ name: this.actions[0].routeName });
      }
    },
  },
};
</script>
